export default {
	name: 'Questions',
	created () {
		this.$nextTick(function () {
			this.GetFrequentlyAskedQuestions()

		})
	},
	mounted () {

	},
	props: [],
	data () {
		return {
			QusetionsArr: [],
			NavSonArr: [],
			IssueConArr: [],
			NavId: 0,
			NavIdSon: null,
			ArrangeActive6: null,
			ArrangeActive6Of: false
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取Questions的数据
		GetFrequentlyAskedQuestions: function () {
			let _this = this
			$.ajax({
				type: "post",
				url: process.env.VUE_APP_API + "GetFrequentlyAskedQuestions",
				data: { token: "webkey" },
				dataType: "json",
				success: data => {
					if (data.status === "SUCCESS") {
						let arr = []
						data.text.filter((c, i) => {
							if (data.text.findIndex(j => j.category == c.category) == i) {
								this.QusetionsArr.push(
									{
										id: i,
										secondLevel: false,
										name: c.category,
										issue: [],
									}
								)
							}
						})
						$.each(this.QusetionsArr, (j, q) => {
							$.each(data.text, (i, c) => {
								if (q.name == c.category) {
									q.issue.push(
										{
											issueName: c.question,
											answer: c.answer
										}
									)
								}
							})
						})
						this.IssueConArr = this.QusetionsArr[0].issue
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		},
		NavCon: function (data) {
			this.NavSonArr = []
			this.IssueConArr = []
			this.NavId = data.id
			if (data.secondLevel) {
				this.NavSonArr = data.issue
			} else {
				this.IssueConArr = data.issue
			}
			this.ArrangeActive6Of = false
			$('#Questions').find('.Arrange').find('li').find('.Text_a').slideUp()
		},
		NavConSon: function (id, data) {
			this.IssueConArr = []
			this.NavIdSon = id
			this.IssueConArr = data.subitemIssue


		},
		ArrangeActive6Clcik (active, data) {
			if (data.answer.length !== 0) {
				this.ArrangeActive6 = active
				this.ArrangeActive6Of = !this.ArrangeActive6Of
				$('#Questions').find('.Arrange').find('li').eq(active).siblings().find('.Text_a').slideUp()
				$('#Questions').find('.Arrange').find('li').eq(active).find('.Text_a').slideToggle()
			}

		}
	}
}
